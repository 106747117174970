<template>
  <el-dialog class="small-padding" :title="$t('elevator.checkMultiReport')" :visible.sync="dialogVisible" width="90%"
    top="1vh" append-to-body @close="onDialogClose">
    <div style="width: 39%;float: left;">
      <el-table border :data="report.elevatorList" height="75vh" style="margin-top: 10px;margin-bottom: 10px">
        <table-column prop="no" :label="$t('elevator.no')" align="center" :width="125"></table-column>
        <table-column prop="name"
          :label="$t('elevatorNetApply.elevatorName')"></table-column>
        <table-column prop="useUnitName" :label="$t('elevator.useUnit')"></table-column>
        <table-column prop="orgName" :label="$t('elevator.belongCompany')"></table-column>
        <table-column :label="$t('common.operation')" width="90px" align="center" :tooltip="false">
          <template #default="scope">
            <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
              {{ $t("common.delete") }}
            </el-button>
          </template>
        </table-column>
      </el-table>
      <div>
        <el-checkbox v-model="isAdd" label="">{{ $t("elevator.saveReport") }}</el-checkbox>
      </div>
      <div style="margin-top: 15px">
        <el-form ref="form" label-width="70px" :model="report">
          <el-form-item :label="$t('elevator.reportName')">
            <el-input v-model="report.templateName" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-form ref="form" :label-width="$l('120px', '100px')" :model="stateDate">
        <el-form-item :label="$t('elevator.reportStateCycle')" :rules="$rule.notNull">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" type="daterange"
            :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" @change="handleDateChange" @clear="clearDateRange">
          </el-date-picker>
        </el-form-item>
      </el-form> -->
    </div>
    <div style="width: 59%;float: left;margin-left: 2%">
      <vm-table ref="vmTable2" v-loading="loading2" :filter.sync="search2" url="elevators/page" multi-selection="true"
        @row-click="handleRowClick" @selection-change="handleMultiSelectChange">
        <template slot="adSearch">
          <vm-search :label="$t('maintWorkOrder.filter')">
            <el-input v-model.trim="search2.filter" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
        </template>
        <template slot="toolbar">
          <el-button class="addBtn" @click="batchAdd">
            {{ $l("common.edit", "批量新增") }}
          </el-button>
        </template>
        <table-column prop="no" :label="$t('elevator.no')" :width="100"></table-column>
        <table-column prop="name"
          :label="$t('dayWeekMonthManger.elevatorName')"></table-column>
        <table-column prop="regNo" :label="$t('elevator.registerCode')"></table-column>
        <table-column prop="dtuCode" :label="$t('elevator.dtuCode')" align="center"></table-column>
        <table-column prop="useUnitName" :label="$t('elevator.useUnit')"></table-column>
        <table-column :label="$t('common.operation')" :width="90" align="center" :tooltip="false">
          <template #default="scope">
            <el-button type="primary" @click="handleAdd(scope.row)">{{ $t("common.add") }}</el-button>
          </template>
        </table-column>
      </vm-table>
    </div>
    <span slot="footer">
      <el-button @click="onDialogClose">{{ $t("elevatorNetApply.cancel") }}</el-button>
      <el-button type="primary" @click="checkHandle">{{ $t("contract.check") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialogVisible: false,
      elevatorList: [],
      multiSelect: [],
      // dateRange: [],
      stateDate: {
        startTime: "",
        endTime: "",
      },
      search2: {
        filter: "",
      },
      isAdd: true,
      report: {
        templateId: "",
        templateName: "",
        elevatorIdList: [],
        elevatorList: [],
      },
      loading1: false,
      loading2: false,
    };
  },
  methods: {
    // 查看(跳转电梯AI维保系统多梯统计报告)
    checkHandle() {
      if (this.report.elevatorList.length === 0) {
        this.$message.error(this.$t("elevator.selectElevator"));
      } else {
        if (this.isAdd) {
          if (!this.report.templateName) {
            this.$message.error(this.$t("elevator.templateNameNotNull"));
            return;
          }
          let params = {
            templateId: this.report.templateId,
            templateName: this.report.templateName,
            elevatorIdList: this.report.elevatorList.map(item => item.id),
          };
          this.$http.post("maintenanceReport/saveElevatorEventReportTemplate", params).then(res => {
            this.dialogVisible = false;
            this.$emit("save-success");
          });
        }


        // 需要生成报告的电梯id
        const elevatorsIds = this.report.elevatorList.map(item => item.id);
        // this.$router.resolve 是 Vue Router 提供的一个方法，用于解析路由地址，并返回一个包含路由信息对象
        const route = this.$router.resolve({
          name: "multiElevatorReport",
          params: {
            elevatorsIds: JSON.stringify(elevatorsIds),
            reportName: this.report.templateName ? this.report.templateName : "各项目重点关注电梯汇总"
          },
        });
        this.dialogVisible = false;
        // 打开新的窗口
        window.open(route.href, "_blank");
      }
    },

    open(templateId) {
      this.dialogVisible = true;
      this.getList2(1);
      if (templateId) {
        this.getData(templateId);
      }
    },
    getData(templateId) {
      const params = {
        templateId,
      };
      this.$http.post("maintenanceReport/queryElevatorEventReportTemplateDetail", params).then(res => {
        this.report = Object.assign(this.report, res.data);
      });
    },
    getList2(pageNum) {
      this.loading2 = false;
      this.$nextTick(() => {
        this.$refs.vmTable2.getList(pageNum);
      });
    },
    check() {
      if (this.report.elevatorList.length === 0) {
        this.$message.error(this.$t("elevator.selectElevator"));
        return;
      }
      if (!this.stateDate.startTime && !this.stateDate.endTime) {
        this.$message.error(this.$t("elevator.selectReporting"));
        return;
      }
    },
    handleRowClick(row, column) {
      if (column && column.label === this.$t("common.operation")) {
        return;
      }
      this.$refs.vmTable2.$refs.elTable.toggleRowSelection(row);
    },
    handleMultiSelectChange(val) {
      this.multiSelect = val;
      console.log(this.multiSelect);
    },
    batchAdd() {
      if (this.multiSelect.length !== 0) {
        if (this.report.elevatorList.length !== 0) {
          let list = [];
          this.report.elevatorList.forEach(i => list.push(i.id));
          for (let id of list) {
            for (let item of this.multiSelect) {
              if (item.id === id) {
                this.multiSelect.splice(this.multiSelect.indexOf(item), 1);
              }
            }
          }
        }
        this.multiSelect.forEach(i => this.report.elevatorList.push(i));
      } else {
        this.$message.error(this.$t("elevator.selectOne"));
      }
    },
    handleAdd(row) {
      if (this.report.elevatorList.length !== 0) {
        let list = [];
        this.report.elevatorList.forEach(item => list.push(item.id));
        if (list.indexOf(row.id) === -1) {
          this.report.elevatorList.push(row);
        }
      } else {
        this.report.elevatorList.push(row);
      }
    },
    deleteRow(index) {
      this.report.elevatorList.splice(index, 1);
    },
    onDialogClose() {
      this.dialogVisible = false;
      this.report.elevatorList = [];
      this.report.templateName = "";
      this.report.elevatorIdList = [];
      this.report.templateId = "";
    },
  },
};
</script>